import React, { useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import {
    Container, Grid, TextField, Typography,
    Button, Paper, Fab} from "@material-ui/core";
import { requesicoes } from "../../../utils/services";
import './student.css';
import { Alert, AlertTitle, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Swal from 'sweetalert2';



let cursos = [];
let turmas = [];
let anosFreq = []
let estudantes = [];
let testes = [];
// let quantidadeReprovados=0;

export default function NovaMatricula(props) {

    const [isLoadingData, setIsLoadingData] = useState(true);
    const [to, setTo] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [updateDom, setUpdateDom] = useState(false);
    const [mostrarMensagem, setMostrarMensagem] = useState(false);


    useEffect(() => {
        init();
    }, [])


    async function init() {

        const [requestCursos, requestTurma, requesteAnoFrequencia] = await Promise.all([requesicoes().buscarCurso(), requesicoes().buscarTurmas(), requesicoes().buscarAnosFreque()]);
        cursos = requestCursos.data;
        turmas = requestTurma.data;
        anosFreq = requesteAnoFrequencia.data;
        setIsLoadingData(false);
    }


    async function carregarAproveitamento(e, ano) {

        const aproveitamento = await Promise.resolve(requesicoes().getAproveitamentoByEstudanteAndAno(`/${e}/${ano}?campos=id,estadoFinal`));
        const quantidadeReprovados = aproveitamento.data.filter(item => item.estadoFinal === "Reprovado").length;
        return {
            quantidadeReprovados,
            estadoFinal: quantidadeReprovados > 2 ? "Reprovado":"Aprovado"
            //aproveitamento.data[0]?.estadoFinal || '' // Assume o primeiro estado final
        };
        /* return {
             quantidadeReprovados,
             reprovado: quantidadeReprovados > 2
         };*/

    }
    const [formData, setFormData] = useState({
        curso: '',
        ano: '',
        turma: ''
    });

    const { curso, ano, turma } = formData;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    async function handleSearch() {
        if (curso && ano && turma) {

            const estudante = await Promise.resolve(requesicoes().getEstudanteByCursoAndAnoAndTurma(`/${curso}/${ano}/${turma}?campos=id,nome,apelido,numero`));
            estudantes = estudante.data;

            for (const e of estudantes) {
                const { quantidadeReprovados, estadoFinal } = await carregarAproveitamento(e.id, ano);
                e.quantidadeReprovados = quantidadeReprovados;
                e.estadoFinal = estadoFinal;
            }


            setUpdateDom(!updateDom);
            setMostrarMensagem(false);
            if (estudantes.length === 0) {
                setMostrarMensagem(true);
            } else {
                estudantes.forEach((estudante) => {
                    carregarTestes(estudante.id);
                });
            }
        } else {
            estudantes = [];
            setUpdateDom(!updateDom);
            setMostrarMensagem(true);
        }

    };

    async function carregarTestes(id) {
        const teste = await Promise.resolve(requesicoes().getTesteByEstudante(`/${id}?campos=id,nota,avaliacao.tipoAvaliacao,estudante.id,cadeira.id,cadeira.nome`));
        testes = teste.data;
    }



    async function handleMatricular(formData) {
        const informacoesTabela = formData
        try {
            await Promise.resolve(requesicoes().renovarMatricular([informacoesTabela]));//${formData.turma}
            Swal.fire(
                'Exito!',
                'Matricula realizada com sucesso!',
                'success'
            )
            setTo('/student/list');
            setRedirect(true);
            // setUpdateTable(true);
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: e.response.data.message,
            })
            // setUpdateTable(true);
        }
    }

    if (redirect)
        return (<Redirect to={to} />)
    else
        return (
            <div style={{ paddingRight: '20px' }}>
                <Paper>

                    <Container><br />
                        <Typography variant="h6">Nova Matricula</Typography><br />
                        <Grid container spacing={2}>
                            <Grid item sm={4}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    select
                                    name='curso'
                                    margin="dense"
                                    value={curso}
                                    onChange={handleInputChange}
                                    id="curso"
                                    fullWidth
                                    label="Curso"
                                >
                                    {cursos.map((opt) => (
                                        <MenuItem value={opt.id} key={opt.id}>{opt.nome}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item sm={4}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    select
                                    margin="dense"
                                    name='ano'
                                    value={ano}
                                    onChange={handleInputChange}
                                    id="ano"
                                    fullWidth
                                    label="Ano de Frequencia"
                                >
                                    {anosFreq.map((opt) => (
                                        <MenuItem value={opt.values} key={opt.id}>{opt.label}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item sm={2}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    select
                                    margin="dense"
                                    name='turma'
                                    value={turma}
                                    onChange={handleInputChange}
                                    id="turma"
                                    fullWidth
                                    label="Turma"
                                >
                                    {turmas.map((opt, i) => (
                                        <MenuItem value={opt} key={i}>{opt}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item sm={2} style={{ paddingTop: '10px', textAlign: 'right' }}>
                                <Button onClick={handleSearch} color="secondary">Buscar</Button>
                            </Grid>
                        </Grid>

                    </Container>

                    <br />
                </Paper > <br />

                {mostrarMensagem && ( // Exibir a mensagem somente se mostrarMensagem for verdadeiro
                    <Alert severity="warning">
                        <AlertTitle>Aviso</AlertTitle>
                        {estudantes.length !== 0 ? 'Selecione todos os campos acima.' : 'Nao encontrou estudante dos dados escolhido. Porfavor tenha certeza de que exite curso da turma e ano escolhido'}
                    </Alert>
                )}

                {estudantes.length > 0 && (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>NO</TableCell>
                                    <TableCell align="left">Numero</TableCell>
                                    <TableCell align="left">Nome Completo</TableCell>
                                    <TableCell align="center">Quat. Cad Reprovado</TableCell>
                                    <TableCell align="center">Observacao</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {estudantes.map((row, i) => (

                                    <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                   
                                    >
                                        <TableCell component="th" scope="row" style={{ color: row.estadoFinal === 'Reprovado' ? 'red' : 'black' }}>
                                            {i + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{ color: row.estadoFinal === 'Reprovado' ? 'red' : 'black' }}>
                                            {row.numero}
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{ color: row.estadoFinal === 'Reprovado' ? 'red' : 'black' }}>
                                            {row.nome} {row.apelido}
                                        </TableCell>
                                        <TableCell align="center" style={{ color: row.estadoFinal === 'Reprovado' ? 'red' : 'black' }}>{row.quantidadeReprovados}</TableCell>
                                        <TableCell align="center" style={{ color: row.estadoFinal === 'Reprovado' ? 'red' : 'black' }}>{ row.estadoFinal} </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
                }
                :<></>
                {estudantes.length > 0 &&
                    (<Fab onClick={() => handleMatricular(formData)} variant="extended" size="medium"
                        style={{
                            position: 'fixed',
                            bottom: '10px', zIndex: 100,
                            right: '15px'
                        }} color="primary">Renovar Matricula</Fab>)}
            </div >)
}