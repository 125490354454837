import React from 'react';
import {FormattedMessage} from 'react-intl';

import {
    IconAtom,
    IconBasket,
    IconBellRinging,
    IconBorderAll,
    IconBorderRadius,
    IconBoxMultiple,
    IconBrandChrome,
    IconBrandGravatar,
    IconBrush,
    IconBug,
    IconCalendar,
    IconChartArcs,
    IconChartCandle,
    IconChartInfographic,
    IconCircle,
    IconCircleOff,
    IconClipboardList,
    IconDashboard,
    IconDeviceAnalytics,
    IconFiles,
    IconForms,
    IconHelp,
    IconId,
    IconKey,
    IconLayoutList,
    IconLoader,
    IconLockAccess,
    IconMail,
    IconMenu,
    IconMessages,
    IconNfc,
    IconPalette,
    IconPencil,
    IconPhoneCall,
    IconPictureInPicture,
    IconReceipt2,
    IconRun,
    IconShadow,
    IconShape,
    IconShieldLock,
    IconSitemap,
    IconTools,
    IconTypography,
    IconUser,
    IconUserCheck
} from '@tabler/icons';

const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics,

    IconChartArcs: IconChartArcs,
    IconClipboardList: IconClipboardList,
    IconChartInfographic: IconChartInfographic,

    IconForms: IconForms,
    IconReceipt2: IconReceipt2,
    IconPencil: IconPencil,
    IconPalette: IconPalette,
    IconShadow: IconShadow,
    IconPhoneCall: IconPhoneCall,
    IconBrandChrome: IconBrandChrome,
    IconFiles: IconFiles,
    IconAtom: IconAtom,
    IconTools: IconTools,
    IconBrush: IconBrush,
    IconLockAccess: IconLockAccess,
    IconShieldLock: IconShieldLock,
    IconKey: IconKey,
    IconTypography: IconTypography,
    IconMenu: IconMenu,
    IconBoxMultiple: IconBoxMultiple,
    IconCircleOff: IconCircleOff,
    IconCircle: IconCircle,
    IconBorderRadius: IconBorderRadius,
    IconBrandGravatar: IconBrandGravatar,
    IconShape: IconShape,
    IconUserCheck: IconUserCheck,
    IconId: IconId,
    IconLayoutList: IconLayoutList,
    IconBug: IconBug,
    IconLoader: IconLoader,
    IconRun: IconRun,
    IconUser: IconUser,
    IconHelp: IconHelp,
    IconSitemap: IconSitemap,
    IconPictureInPicture: IconPictureInPicture,
    IconMail: IconMail,
    IconMessages: IconMessages,
    IconNfc: IconNfc,
    IconCalendar: IconCalendar,
    IconBellRinging: IconBellRinging,
    IconBorderAll: IconBorderAll,
    IconChartCandle: IconChartCandle,
    IconBasket: IconBasket
};

const menuItems = {
    items: [

        {
            id: 'registo_academico',
            title: <FormattedMessage id="registo_academico" />,
            type: 'group',
            children: [
                {
                    id: 'estudante',
                    title: <FormattedMessage id="estudante" />,
                    type: 'collapse',
                    // url: '/dashboard/default',
                    icon: icons['IconSchool'],
                    breadcrumbs: false,
                    children: [
                                {
                                    id: 'cadastro',
                                    title: <FormattedMessage id="cadastro" />,
                                    type: 'item',
                                    url: '/student/student',
                                    icon: icons['IconFilePlus']
                                },
                                {
                                    id: 'lista',
                                    title: <FormattedMessage id="lista" />,
                                    type: 'item',
                                    url: '/student/list',
                                    icon: icons['IconListSearch']
                                    //target: true
                                },
                                {
                                    id: 'lista_inscrito',
                                    title: <FormattedMessage id="lista_inscrito" />,
                                    type: 'item',
                                    url: '/inscrito/list',
                                    icon: icons['IconListSearch']
                                    //target: true
                                },
                                {

                                    id: 'matricula',
                                    title: <FormattedMessage id="matricula" />,
                                    type: 'item',
                                    url: '/student/matricula',
                                    icon: icons['IconListSearch']
                                    

                                },
                                {
                                    id: 'certificado',
                                    title: <FormattedMessage id="certificado" />,
                                    type: 'item',
                                    url: '#',
                                    //target: true
                                },
                                {
                                    id: 'declaracao',
                                    title: <FormattedMessage id="declaracao" />,
                                    type: 'item',
                                    url: '#',
                                    //target: true
                                }

                    ]
                },
                {
                    id: 'pauta',
                    title: <FormattedMessage id="pauta" />,
                    type: 'item',
                    url: '/pauta/pauta',
                    icon: icons['IconNotes'],
                    breadcrumbs: false,
                   
                },
                {
                    id: 'usuario',
                    title: <FormattedMessage id="usuario" />,
                    type: 'item',
                    url: '/admin/usuarios',
                    icon: icons['IconMoodSmile'],
                    breadcrumbs: false,
                   
                }
                
            ]
        },

    ]
};

export default menuItems;
