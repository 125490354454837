import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    IconAtom,
    IconBasket,
    IconBellRinging,
    IconBorderAll,
    IconBorderRadius,
    IconBoxMultiple,
    IconBrandChrome,
    IconBrandGravatar,
    IconBrush,
    IconBug,
    IconCalendar,
    IconChartArcs,
    IconChartCandle,
    IconChartInfographic,
    IconCircle,
    IconCircleOff,
    IconClipboardList,
    IconDashboard,
    IconDeviceAnalytics,
    IconFiles,
    IconForms,
    IconHelp,
    IconId,
    IconKey,
    IconLayoutList,
    IconLoader,
    IconLockAccess,
    IconMail,
    IconMenu,
    IconMessages,
    IconNfc,
    IconPalette,
    IconPencil,
    IconPhoneCall,
    IconPictureInPicture,
    IconReceipt2,
    IconRun,
    IconShadow,
    IconShape,
    IconShieldLock,
    IconSitemap,
    IconTools,
    IconTypography,
    IconUser,
    IconUserCheck
} from '@tabler/icons';

const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics,

    IconChartArcs: IconChartArcs,
    IconClipboardList: IconClipboardList,
    IconChartInfographic: IconChartInfographic,

    IconForms: IconForms,
    IconReceipt2: IconReceipt2,
    IconPencil: IconPencil,
    IconPalette: IconPalette,
    IconShadow: IconShadow,
    IconPhoneCall: IconPhoneCall,
    IconBrandChrome: IconBrandChrome,
    IconFiles: IconFiles,
    IconAtom: IconAtom,
    IconTools: IconTools,
    IconBrush: IconBrush,
    IconLockAccess: IconLockAccess,
    IconShieldLock: IconShieldLock,
    IconKey: IconKey,
    IconTypography: IconTypography,
    IconMenu: IconMenu,
    IconBoxMultiple: IconBoxMultiple,
    IconCircleOff: IconCircleOff,
    IconCircle: IconCircle,
    IconBorderRadius: IconBorderRadius,
    IconBrandGravatar: IconBrandGravatar,
    IconShape: IconShape,
    IconUserCheck: IconUserCheck,
    IconId: IconId,
    IconLayoutList: IconLayoutList,
    IconBug: IconBug,
    IconLoader: IconLoader,
    IconRun: IconRun,
    IconUser: IconUser,
    IconHelp: IconHelp,
    IconSitemap: IconSitemap,
    IconPictureInPicture: IconPictureInPicture,
    IconMail: IconMail,
    IconMessages: IconMessages,
    IconNfc: IconNfc,
    IconCalendar: IconCalendar,
    IconBellRinging: IconBellRinging,
    IconBorderAll: IconBorderAll,
    IconChartCandle: IconChartCandle,
    IconBasket: IconBasket
};

const menuItems = {
    items: [

        {
            id: 'departamento',
            title: <FormattedMessage id="departamento" />,
            type: 'group',
            children: [
                {
                    id: 'docente',
                    title: <FormattedMessage id="docente" />,
                    type: 'collapse',
                    //url: '/dashboard/default',
                    icon: icons['IconUsers'],
                    breadcrumbs: false,
                    children: [
                        {
                            id: 'lista',
                            title: <FormattedMessage id="lista" />,
                            type: 'item',
                            url: '/teacher/list',
                            icon: icons['IconListSearch']
                            //target: true
                        },
                        {
                            id: 'pauta',
                            title: <FormattedMessage id="pauta" />,
                            type: 'item',
                            url: '/pauta/pauta',
                            icon: icons['IconNotes'],
                            breadcrumbs: false,

                        }

                    ]
                },
                {
                    id: 'disciplina',
                    title: <FormattedMessage id="disciplina" />,
                    type: 'collapse',
                    url: '/dashboard/default',
                    icon: icons['IconBook'],
                    breadcrumbs: false,
                    children: [
                        {
                            id: 'lista',
                            title: <FormattedMessage id="lista" />,
                            type: 'item',
                            url: '/subject/list',
                            icon: icons['IconListSearch']
                            // target: true
                        }



                    ]
                },
                {
                    id: 'curso',
                    title: <FormattedMessage id="curso" />,
                    type: 'collapse',
                    //url: '/dashboard/default',
                    icon: icons['IconBookmarks'],
                    breadcrumbs: false,
                    children: [
                        {
                            id: 'lista',
                            title: <FormattedMessage id="lista" />,
                            type: 'item',
                            url: '/course/list',
                            icon: icons['IconListSearch']
                            //target: true
                        }

                    ]
                }
            ]
        },

    ]
};

export default menuItems;
