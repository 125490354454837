import axios from "axios";
import jwt_decode from "jwt-decode";


const API_URL = "http://api.am.ac.mz:8082";



const login = (login, password) => {
  return axios
    .post(API_URL + "/login", {
      login,
      password,
    })
    .then((response) => {
      if (response.data) {
         localStorage.setItem('user', response.data);
      }
     
      return response.data;
    });
};


const logout = () => {
  localStorage.removeItem("user");
  localStorage.clear()
 
};

const getCurrentUser = () => {
  //window.alert(JSON.parse(localStorage.getItem("currentUser")));
  const decoded = jwt_decode(localStorage.getItem("user"));
  return decoded;
};

const authService = {
  login,
  logout,
  getCurrentUser,
};

export default authService;