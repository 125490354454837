import http from './Http'

export const baseUrl = "http://api.am.ac.mz:8082";

const requestHeaders = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',

    }
}

export function requesicoes() {
    return {

        // buscarSexo: () => http.post(`${baseUrl}/login`),
        // login:values =>http.post(`${baseUrl}/login`,JSON.stringify(values), requestHeaders),


        //Usuario Logado
        getUsuarioLogado: (q = "") => http.get(`${baseUrl}/api/usuarios/login/${q}`),
        getUsuarios: (q = "") => http.get(`${baseUrl}/api/usuarios/${q}`),
        postUsuarios:values => http.post(`${baseUrl}/api/usuario/add`, JSON.stringify(values), requestHeaders),
        putSenha:(values,id)=>http.put(`${baseUrl}/api/trocar/senha/${id}`, JSON.stringify(values), requestHeaders),
        deleteUsuario:(id)=>http.delete(`${baseUrl}/api/usuario/${id}`),
         
        // putEstudantes: (values, id) => http.put(`${baseUrl}/estudantes/api/edicao/${values.id}`, JSON.stringify(values), requestHeaders),

        getCalendario:(q = "")=> http.get(`${baseUrl}/dados/calendarios${q}`),
        postCalendario: values => http.post(`${baseUrl}/dados/calendario/add`, JSON.stringify(values), requestHeaders),

       
        //DashBoard
        getQtdEstudanteActivo:() => http.get(`${baseUrl}/estudantes/activos`),
        getQtdEstudante:() => http.get(`${baseUrl}/estudantes/qtds`),
        getQtdReprovados:() => http.get(`${baseUrl}/api/aproveitamento`),

        //Dados Gerais
        buscarSexo: () => http.get(`${baseUrl}/dados/sexo`),
        buscarDocumento: () => http.get(`${baseUrl}/dados/tiposDocumento`),
        buscarDocumentoEst: () => http.get(`${baseUrl}/dados/tiposDocumentoEst`),
        buscarTurmas: () => http.get(`${baseUrl}/dados/turmas`),
        getTurmas :() => http.get(`${baseUrl}/dados/api/turmas`),
        buscarSituacao: () => http.get(`${baseUrl}/dados/situacao`),
        buscarRegimes: () => http.get(`${baseUrl}/dados/regimesServico`),
        buscarAnosFreque: () => http.get(`${baseUrl}/dados/anosFrequencia`),
        buscarSemestre: () => http.get(`${baseUrl}/dados/semestres`),
        buscarNiveis: () => http.get(`${baseUrl}/dados/niveisAcademico`),
        getTipoAvaliacao: () => http.get(`${baseUrl}/dados/tipoAvaliacao`),
        getPrmissoes:() => http.get(`${baseUrl}/dados/permissoes`),


        /*Pais */
        buscarPaises: () => http.get(`${baseUrl}/regional/api/regiao`),
        postPais: values => http.post(`${baseUrl}/regional/api/regiao`, JSON.stringify(values), requestHeaders),

        /*Provincia*/
        buscarProvincia: (q = "") => http.get(`${baseUrl}/regiao/api/provincia${q}`),
        postProvincia: values => http.post(`${baseUrl}/regiao/api/state
        `, JSON.stringify([values]), requestHeaders),
        buscarProvinciaByPais: id => http.get(`${baseUrl}/regiao?id=${id}`),

        /*Cidades*/
        buscarCidades: (q = "") => http.get(`${baseUrl}/regiao/api/cidades${q}`),
        postCidade: values => http.post(`${baseUrl}/regiao/api/state`, JSON.stringify(values), requestHeaders),
        buscarCidadesByProvincia: id => http.get(`${baseUrl}/regiao/api/cidade?id=${id}`),


        /*Curso*/
        getEspecilizacao: (q = "") => http.get(`${baseUrl}/cursos/api/especilaizacoes${q}`),
        //buscarCurso: (q="") => http.get(`${baseUrl}/cursos/api/cursos${q}`),
        //buscarCursoByCadeira: id => http.get(`${baseUrl}/cursos/cursoCadeira?id=${id}`),

        /*Departamentos*/
        buscarDepartamentos: (q = "") => http.get(`${baseUrl}/departamentos/api/lista${q}`),
        postDepartamento: values => http.post(`${baseUrl}/departamentos/api/adicionar`, JSON.stringify(values), requestHeaders),

        /*Cdaeiras */
        buscarCadeiras: (q = "") => http.get(`${baseUrl}/cadeiras/api/cadeiras${q}`),
        getCadeiraById: (q = "") => http.get(`${baseUrl}/cadeiras/api/cadeiras${q}`),
        getCadeiraCurricular: (q = "") => http.get(`${baseUrl}/cadeiras/api/cadeiras/currricular${q}`),
        deleteCadeiraCurricular: (ids) => http.delete(`${baseUrl}/cadeiras/api/cadeiras/currricular/${ids.join(',')}`),
        getCadeiraCurricularByCurriculo:(id) => http.get(`${baseUrl}/cadeiras/api/cadeiras/currricular${id}`),
        postCadeira: values => http.post(`${baseUrl}/cadeiras/api/cadeiras`, JSON.stringify(values), requestHeaders),
        buscarCadeirasByCursoAndAno: (cursoSeleccionado, anoSeleccionado) => http.get(`${baseUrl}/cadeiras/cadeiracursoano?id=${cursoSeleccionado}&ano=${anoSeleccionado}`),
        deleteDisciplina:(id)=>http.delete(`${baseUrl}/cadeiras/api/cadeiras/${id}`),
        /*Metodo por implementar deve fazer Busca das cadeiras em funcao do departamento do usuario*/
        getCadeirasByCurso: id => http.get(`${baseUrl}/cadeiras/cadeiraCurso?id=${id}`),

        /*Estudante*/
        // postEstudantes: values => http.post(`${baseUrl}/estudantes`, JSON.stringify(values), requestHeaders),

        buscarCurso: (q = "") => http.get(`${baseUrl}/cursos/api/cursos${q}`),
        postCurso: values => http.post(`${baseUrl}/cursos/api/cursos`, JSON.stringify(values), requestHeaders),

        postTurmas: values => http.post(`${baseUrl}/dados/api/turmas`, JSON.stringify(values), requestHeaders),
        getTurmas: (q = "") => http.get(`${baseUrl}/dados/api/turmas${q}`),
        getTurmasCadeiras: (q = "") => http.get(`${baseUrl}/turmas/api/turmas${q}`),
        getTurmasProfessor: (q = "") => http.get(`${baseUrl}/turmas/api/turmasProf${q}`),
        getTurmaCadeirabyCadeiraAndTurma: (q = "") => http.get(`${baseUrl}/turmas/api/turmas${q}`),
        postTurmasCadeiras: values => http.post(`${baseUrl}/turmas/api/turmas`, JSON.stringify(values), requestHeaders),
        getEstudanteTurma: (q = "") => http.get(`${baseUrl}/teste/api/lista/turma${q}`),


        postAvalicoes: values => http.post(`${baseUrl}/api/avaliacao/add`, JSON.stringify(values), requestHeaders),
        getTipoAvaliacoes: (q = "") => http.get(`${baseUrl}/dados/tipoAvaliacao${q}`),
        getAvaliacoes: (q = "") => http.get(`${baseUrl}/api/avaliacoes${q}`),
        getAvaliacoesByCadeira: (q = "") => http.get(`${baseUrl}/api/avaliacoes${q}`),
        getAvaliacoesByCadeiraAndTurma: (q = "") => http.get(`${baseUrl}/api/avaliacoes${q}`),
        getAvaliacoesExameByCadeiraAndTurma: (q = "") => http.get(`${baseUrl}/api/avaliacoesExame${q}`),

        getTeste: (q = "") => http.get(`${baseUrl}/teste/api/teste${q}`),
        getTesteByCurso: (q = "") => http.get(`${baseUrl}/teste/api/turma${q}`),
        postTeste: values => http.post(`${baseUrl}/teste/api/teste`, JSON.stringify(values), requestHeaders),
        getTesteByEstudante: (q = "") => http.get(`${baseUrl}/teste/api/teste${q}`),
        /*Aproveitamentos*/
        getAproveitamento: (q = "") => http.get(`${baseUrl}/api/aproveitamentos${q}`),
        getAproveitamentoByEstudanteAndAno: (q = "") => http.get(`${baseUrl}/api/aproveitamento${q}`),

        // Inscricao
        buscarInscritos: (q = "") => http.get(`${baseUrl}/inscrito/api/lista${q}`),
        geInscricaoById:(q = "")=>http.get(`${baseUrl}/inscrito/api/inscrito${q}`),


        /*Estudante*/
        buscarEstudantes: (q = "") => http.get(`${baseUrl}/estudantes${q}`),
        criarCodigo: (q = "") => http.get(`${baseUrl}/inscrito/api/codigo${q}`),
        matricular: () => http.post(`${baseUrl}/matricula/api/nova`),
        renovarMatricular: (values) => http.post(`${baseUrl}/matricula/api/renovacao`,values, requestHeaders),

        getEstudanteByCursoAndAnoAndTurma: (q = '') => http.get(`${baseUrl}/matricula/api/lista/estudante${q}`),
        // getestudanteById:(id)=>http.get(`${baseUrl}/estudantes/api/estudantes?id=${id}`),
        getEstudantesMatriculados: (q = "") => http.get(`${baseUrl}/estudantes/matriculado${q}`),
        getestudanteById: (q = "") => http.get(`${baseUrl}/estudantes/api/estudante${q}`),
        getestudanteByCurso: (id) => http.get(`${baseUrl}/estudantes/curso?id=${id}`),
        postEstudantes: values => http.post(`${baseUrl}/inscrito/api/inscricao`, JSON.stringify(values), requestHeaders),
        putEstudantes: (values, id) => http.put(`${baseUrl}/estudantes/api/edicao/${values.id}`, JSON.stringify(values), requestHeaders),
        postMatriculas: values => http.post(`${baseUrl}/matricula`, values, requestHeaders()),
        getMatriculaByEstudante: (id) => http.get(`${baseUrl}/matricula/estudante${id}`),
        getEstudanteByNumero:(q = "") => http.get(`${baseUrl}/estudantes/codigo/${q}`),

        /*Professor */
        postProfessor: values => http.post(`${baseUrl}/docentes/api/docentes`, JSON.stringify(values), requestHeaders),
        
        putProfessor:(values)=>http.put(`${baseUrl}/docentes/api/docentes/update/${values.id}`, JSON.stringify(values), requestHeaders),
        buscarProfessor: (q = "") => http.get(`${baseUrl}/docentes/api/docentes${q}`),
        getDocenteByNumero:(q = "") => http.get(`${baseUrl}/docentes/api/numero/${q}`),
        buscarProfessorByDpt:(q = "") => http.get(`${baseUrl}/docentes/api/dpt${q}`),
        getProfessorByTurma:(q = "") => http.get(`${baseUrl}/turmas/api/turmas${q}`),
        getProfessorById:(q = "")=>http.get(`${baseUrl}/docentes/api/docentes${q}`),



        /* Curriculos */
        buscarCurriculos: (q = "") => http.get(`${baseUrl}/curiculos/api/curricula${q}`),
        postCurricula: values => http.post(`${baseUrl}/curiculos/api/curricula`, JSON.stringify(values), requestHeaders),
        buscarCurriculosCurso: (curso, q = "") => http.get(`${baseUrl}/curiculos/api/curriculo/curso/${curso}/${q}`),


    }
}