import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import NovaMatricula from '../views/forms/student/matricula';
import AuthService from "../utils/services/auth";

import MainLayout from './../layout/MainLayout';


const DashboardDefault = lazy(() => import('../views/dashboard/Default'));
const DashboardPalificacao = lazy(() => import('../views/dashboard/HomePagePlanificacao'));
const HomePage = lazy(() => import('../views/dashboard/HomePage'));
const Usuario = lazy(() => import('../views/forms/user/List'));
const Profile = lazy(() => import('../views/pages/authentication/login/profile'));
const Login = lazy(() => import('../views/pages/authentication/login/FirebaseLogin'));
const NewStudent = lazy(() => import('../views/forms/student/student'));
const StudentDetails = lazy(() => import('../views/forms/student/detalhes'));
const StudentEdit = lazy(() => import('../views/forms/student/student_edit'));
const TeacherEdit = lazy(() => import('../views/forms/teacher/teacher_edit'));
const InscritoEdit = lazy(() => import('../views/forms/inscrito/inscricao_edit'));
const NewClass = lazy(() => import('../views/forms/class/class'));
const NewClassTeacherList = lazy(() => import('../views/forms/class/turmasProfessor'));
const Calendariio = lazy(() => import('../views/forms/curriculo/calendario'));
const DepartamentoList = lazy(() => import('../views/forms/departamento/list'));
const ClassList = lazy(() => import('../views/forms/class/list'));
const ClassEdit = lazy(() => import('../views/forms/class/class_edit'));
const TrocaSenha = lazy(() => import('../views/forms/user/TrocarSenha'));
const PerfilEstudante = lazy(() => import('../views/forms/student/Perfil'));
const PerfilProfessor = lazy(() => import('../views/forms/teacher/Perfil'));
const HistoricoStudent = lazy(() => import('../views/forms/student/detalhes'));
const CurriculoDetails = lazy(() => import('../views/forms/curriculo/detalhes'));
const ListStudent = lazy(() => import('../views/forms/student/list'));
const ListInscrito = lazy(() => import('../views/forms/inscrito/list'));
const ListTeacher = lazy(() => import('../views/forms/teacher/list'));
const NewTeacher = lazy(() => import('../views/forms/teacher/teacher'));
const DocenteNotas = lazy(() => import('../views/forms/teacher/notas'));
const PautaRegistoAcademico = lazy(() => import('../views/forms/pauta/pauta'));
const ListSubject = lazy(() => import('../views/forms/subject/list'));
const ListPais = lazy(() => import('../views/forms/pais/list'));
const ListProvincia = lazy(() => import('../views/forms/provincia/list'));
const ListCidade = lazy(() => import('../views/forms/cidade/list'));
const ListNotas = lazy(() => import('../views/forms/pauta/pautaCadeira'));
const PautaProfessor = lazy(() => import('../views/forms/teacher/pauta'));
const CardProfessor = lazy(() => import('../views/forms/teacher/notas'));
const ExameProfessor = lazy(() => import('../views/forms/teacher/exame'));
const NotasExame = lazy(() => import('../views/forms/teacher/notasExame'));
const EstudanteFreque = lazy(() => import('../views/forms/student/frequencia'));
const EstudanteExame = lazy(() => import('../views/forms/student/exame'));
const CarregarEstudantes = lazy(() => import('../views/forms/inscrito/carregar'));
const CarregarCadeiras = lazy(() => import('../views/forms/subject/carregar'));
const ListCourse = lazy(() => import('../views/forms/course/list'));
const NovoCurriculo = lazy(() => import('../views/forms/curriculo/curriculo'));
const Curriculo = lazy(() => import('../views/forms/curriculo/list'));
const TableBasic = lazy(() => import('../views/forms/tables/TableBasic'));
const TableDense = lazy(() => import('../views/forms/tables/TableDense'));
const UtilsTypography = lazy(() => import('../views/utilities/typography'));
const UtilsColor = lazy(() => import('../views/utilities/color'));
const UtilsShadow = lazy(() => import('../views/utilities/shadow'));
const UtilsMaterialIcons = lazy(() => import('../views/utilities/icons/MaterialIcons'));
const UtilsTablerIcons = lazy(() => import('../views/utilities/icons/TablerIcons'));
const SamplePage = lazy(() => import('../views/sample-page'));

const NotFound = () => {
    return <h1>Página não encontrada</h1>;
};


const MainRoutes = () => {
    const location = useLocation();

    if (localStorage.getItem("user") !== null) {
        const currentUser = AuthService.getCurrentUser();
        //ROUTAS para O administrador
        if (currentUser.authorities.includes('ADMIN')) {
            return (
                <Route
                    path={[
                        '/dashboard/default',
                        '/login/profile',
                        '/student/student',
                        '/student/detalhes',
                        '/curriculo/detalhes',
                        '/student/student_edit',
                        '/student/inscricao_edit',
                        '/class/class',
                        '/turma/professores',
                        '/class/detalhes',
                        '/class/list',
                        '/departamento/list',
                        '/class/class_edit',
                        '/student/matricula',
                        '/student/list',
                        '/inscrito/list',
                        '/teacher/list',
                        '/teacher/teacher',
                        '/teacher/notas',
                        '/curriculo/list',
                        '/curriculo/curriculo',
                        '/pauta/pauta',
                        '/pauta/pautaCadeira',
                        '/teacher/pauta',
                        '/teacher/notas/pauta',
                        '/teacher/exame',
                        '/teacher/notasExame',
                        '/pauta/frequencia',
                        '/estudante/pauta/exame',
                        '/pauta/exame',
                        '/carregar/inscrito',
                        '/carregar/cadeira',
                        '/troca/senha',
                        '/subject/list',
                        '/admin/usuarios',
                        '/course/list',
                        '/pais/list',
                        '/provincia/list',
                        '/cidade/list',
                        '/tables/tbl-basic',
                        '/tables/tbl-dense',
                        '/utils/util-typography',
                        '/utils/util-color',
                        '/utils/util-shadow',
                        '/icons/tabler-icons',
                        '/icons/material-icons',
                        '/teacher/update',
                        '/sample-page'
                    ]}
                >
                    <MainLayout showBreadcrumb={true}>
                        <Switch location={location} key={location.pathname}>
                            <Route path="/dashboard/default" component={DashboardDefault} />
                            <Route path="/login/profile" component={Profile} />
                            <Route path="/student/student" component={NewStudent} />
                            <Route path="/student/detalhes" component={StudentDetails} />
                            <Route path="/curriculo/detalhes" component={CurriculoDetails} />
                            <Route path="/student/student_edit" component={StudentEdit} />
                            <Route path="/student/inscricao_edit" component={InscritoEdit} />
                            <Route path="/teacher/update" component={TeacherEdit} />
                            <Route path="/class/class" component={NewClass} />
                            <Route path="/turma/professores" component={NewClassTeacherList} /> 
                            <Route path="/class/list" component={ClassList} />
                            <Route path="/departamento/list" component={DepartamentoList} />
                            <Route path="/class/class_edit" component={ClassEdit} />
                            <Route path="/pauta/frequencia" component={EstudanteFreque} />,
                            <Route path="/estudante/pauta/exame" component={EstudanteExame} />,
                            <Route path="/carregar/inscrito" component={CarregarEstudantes} />,
                            <Route path="/carregar/cadeira" component={CarregarCadeiras} />,
                            <Route path="/student/matricula" component={NovaMatricula} />,
                            <Route path="/subject/list" component={ListSubject} />
                            <Route path="/pauta/pauta" component={PautaRegistoAcademico} />
                            <Route path="/siguam/view" component={HistoricoStudent} />
                            <Route path="/troca/senha" component={TrocaSenha} />
                            <Route path="/admin/usuarios" component={Usuario} />
                            <Route path="/pauta/pautaCadeira" component={ListNotas} />
                            <Route path="/teacher/pauta" component={PautaProfessor} />
                            <Route path="/teacher/notas" component={CardProfessor} />
                            <Route path="/teacher/exame" component={ExameProfessor} />
                            <Route path="/teacher/notasExame" component={NotasExame} />
                            <Route path="/student/list" component={ListStudent} />

                            <Route path="/inscrito/list" component={ListInscrito} />
                            <Route path="/teacher/list" component={ListTeacher} />
                            <Route path="/teacher/teacher" component={NewTeacher} />
                            <Route path="/teacher/notas/teste" component={DocenteNotas} />
                            <Route path="/course/list" component={ListCourse} />
                            <Route path="/curriculo/list" component={Curriculo} />
                            <Route path="/curriculo/curriculo" component={NovoCurriculo} />
                            <Route path="/pais/list" component={ListPais} />
                            <Route path="/provincia/list" component={ListProvincia} />
                            <Route path="/cidade/list" component={ListCidade} />


                            <Route path="/tables/tbl-basic" component={TableBasic} />
                            <Route path="/tables/tbl-dense" component={TableDense} />

                            <Route path="/utils/util-typography" component={UtilsTypography} />
                            <Route path="/utils/util-color" component={UtilsColor} />
                            <Route path="/utils/util-shadow" component={UtilsShadow} />
                            <Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
                            <Route path="/icons/material-icons" component={UtilsMaterialIcons} />
                            <Route path="/sample-page" component={SamplePage} />

                        </Switch>
                    </MainLayout>
                </Route>

            );
        }
        //ROUTAS para chefe do RA

        if (currentUser.authorities.includes('RA_N1')) {
            return (
                <Route
                    path={[
                        '/dashboard/default',
                        '/student/student',
                        '/student/list',
                        '/inscrito/list',
                        '/pauta/pautaCadeira',
                        '/student/matricula',
                        '/pauta/pauta',
                        '/student/detalhes',
                        '/student/student_edit',
                        '/admin/usuarios',
                        '/carregar/inscrito',
                        '/teacher/pauta',
                        '/troca/senha'

                    ]}
                >

                    <MainLayout showBreadcrumb={true}>
                        <Switch location={location} key={location.pathname}>
                            <Route path="/dashboard/default" component={DashboardDefault} />
                            <Route path="/student/student" component={NewStudent} />
                            <Route path="/student/detalhes" component={StudentDetails} />
                            <Route path="/student/student_edit" component={StudentEdit} />
                            <Route path="/student/list" component={ListStudent} />
                            <Route path="/inscrito/list" component={ListInscrito} />
                            <Route path="/student/matricula" component={NovaMatricula} />
                            <Route path="/pauta/pauta" component={PautaRegistoAcademico} />
                            <Route path="/troca/senha" component={TrocaSenha} />
                            <Route path="/pauta/pautaCadeira" component={ListNotas} />
                            <Route path="/admin/usuarios" component={Usuario} />
                            <Route path="/carregar/inscrito" component={CarregarEstudantes} />,
                            <Route path="/teacher/pauta" component={PautaProfessor} />


                        </Switch>
                    </MainLayout>
                </Route>
            );
        }
        if (currentUser.authorities.includes('RA_N2') || currentUser.authorities.includes('RA_N3')) {
            return (
                <Route
                    path={[
                        '/student/student',
                        '/dashboard/home',
                        '/student/list',
                        '/inscrito/list',
                        '/student/matricula',
                        '/pauta/pauta',
                        '/student/detalhes',
                        '/troca/senha',
                        '/carregar/inscrito',
                        '/pauta/pautaCadeira',
                        '/teacher/pauta',



                    ]}
                >

                    <MainLayout showBreadcrumb={true}>
                        <Switch location={location} key={location.pathname}>
                            <Route path="/dashboard/home" component={HomePage} />
                            <Route path="/student/student" component={NewStudent} />
                            <Route path="/student/detalhes" component={StudentDetails} />
                            <Route path="/student/list" component={ListStudent} />
                            <Route path="/inscrito/list" component={ListInscrito} />
                            <Route path="/student/matricula" component={NovaMatricula} />
                            <Route path="/pauta/pauta" component={PautaRegistoAcademico} />
                            <Route path="/admin/usuarios" component={Usuario} />
                            <Route path="/troca/senha" component={TrocaSenha} />
                            <Route path="/carregar/inscrito" component={CarregarEstudantes} />,
                            <Route path="/pauta/pautaCadeira" component={ListNotas} />
                            <Route path="/teacher/pauta" component={PautaProfessor} />


                        </Switch>
                    </MainLayout>
                </Route>
            );
        }
        //ROUTAS para Planificação
        if (currentUser.authorities.includes("PLAN_N1")) {
            return (
                <Route
                    path={[
                        '/dashboard/home',
                        '/departamento/list',
                        '/teacher/teacher',
                        '/teacher/list',
                        '/subject/list',
                        '/course/list',
                        '/curriculo/list',
                        '/admin/usuarios',
                        '/curriculo/curriculo',
                        '/curriculo/detalhes',
                        '/class/class',
                        '/turma/professores',
                        '/carregar/cadeira',
                        '/class/list',
                        '/class/detalhes',
                        '/class/class_edit',
                        '/troca/senha',
                        '/calendario/list',
                        '/teacher/update'

                    ]}
                >
                    <MainLayout showBreadcrumb={true}>
                        <Switch location={location} key={location.pathname}>
                            <Route path="/dashboard/home" component={DashboardPalificacao} />
                            <Route path="/curriculo/detalhes" component={CurriculoDetails} />
                            <Route path="/departamento/list" component={DepartamentoList} />
                            <Route path="/teacher/teacher" component={NewTeacher} />
                            <Route path="/teacher/list" component={ListTeacher} />
                            <Route path="/subject/list" component={ListSubject} />
                            <Route path="/course/list" component={ListCourse} />
                            <Route path="/curriculo/list" component={Curriculo} />
                            <Route path="/curriculo/curriculo" component={NovoCurriculo} />
                            <Route path="/class/class" component={NewClass} />
                            <Route path="/turma/professores" component={NewClassTeacherList} /> 

                            <Route path="/class/list" component={ClassList} />
                            <Route path="/class/class_edit" component={ClassEdit} />
                            <Route path="/admin/usuarios" component={Usuario} />
                            <Route path="/troca/senha" component={TrocaSenha} />
                            <Route path="/calendario/list" component={Calendariio} />
                            <Route path="/carregar/cadeira" component={CarregarCadeiras} />,
                            <Route path="/teacher/update" component={TeacherEdit} />



                        </Switch>
                    </MainLayout>
                </Route>
            );
        }
        if (currentUser.authorities.includes("PLANIFICACAO")) {
            return (
                <Route
                    path={[
                        '/dashboard/home',
                        '/departamento/list',
                        '/teacher/teacher',
                        '/teacher/list',
                        '/subject/list',
                        '/course/list',
                        '/curriculo/list',
                        '/curriculo/curriculo',
                        '/class/class',
                        '/turma/professores',
                        '/carregar/cadeira',
                        '/class/list',
                        '/class/detalhes',
                        '/class/class_edit',
                        '/troca/senha',
                        '/calendario/list',
                        '/teacher/update',
                        '/curriculo/detalhes'

                    ]}
                >
                    <MainLayout showBreadcrumb={true}>
                        <Switch location={location} key={location.pathname}>
                            <Route path="/dashboard/home" component={HomePage} />
                            <Route path="/departamento/list" component={DepartamentoList} />
                            <Route path="/teacher/teacher" component={NewTeacher} />
                            <Route path="/teacher/list" component={ListTeacher} />
                            <Route path="/subject/list" component={ListSubject} />
                            <Route path="/course/list" component={ListCourse} />
                            <Route path="/curriculo/list" component={Curriculo} />
                            <Route path="/curriculo/curriculo" component={NovoCurriculo} />
                            <Route path="/class/class" component={NewClass} />
                            <Route path="/turma/professores" component={NewClassTeacherList} /> 

                            <Route path="/class/list" component={ClassList} />
                            <Route path="/class/class_edit" component={ClassEdit} />
                            <Route path="/troca/senha" component={TrocaSenha} />
                            <Route path="/calendario/list" component={Calendariio} />
                            <Route path="/carregar/cadeira" component={CarregarCadeiras} />,
                            <Route path="/teacher/update" component={TeacherEdit} />
                            <Route path="/curriculo/detalhes" component={CurriculoDetails} />

                        </Switch>
                    </MainLayout>
                </Route>
            );
        }

        //ROUTAS para Docente

        if (currentUser.authorities.includes("PROFESSOR")) {
            return (
                <Route
                    path={[
                        '/dashboard/home',
                        '/teacher/notas/teste',
                        '/teacher/exame',
                        '/docente/perfil',
                        '/teacher/notas/pauta',
                        '/teacher/notas/teste',
                        '/pauta/pautaCadeira',
                        '/teacher/pauta',
                        '/teacher/notas',
                        '/teacher/exame',
                        '/teacher/notasExame',
                        '/troca/senha',

                    ]}
                >

                    <MainLayout showBreadcrumb={true}>
                        <Switch location={location} key={location.pathname}>
                            <Route path="/dashboard/home" component={HomePage} />
                            <Route path="/teacher/notas/teste" component={DocenteNotas} />
                            <Route path="/teacher/exame" component={ExameProfessor} />
                            <Route path="/teacher/notas/teste" component={DocenteNotas} />
                            <Route path="/pauta/pautaCadeira" component={ListNotas} />
                            <Route path="/teacher/pauta" component={PautaProfessor} />
                            <Route path="/teacher/notas" component={CardProfessor} />
                            <Route path="/teacher/exame" component={ExameProfessor} />
                            <Route path="/teacher/notasExame" component={NotasExame} />
                            <Route path="/troca/senha" component={TrocaSenha} />
                            <Route path="/docente/perfil" component={PerfilProfessor} />

                        </Switch>
                    </MainLayout>
                </Route>
            );
        }

        //ROUTAS para Estudante

        if (currentUser.authorities == "ESTUDANTE") {
            return (
                <Route
                    path={[
                        '/dashboard/home',
                        '/pauta/frequencia',
                        '/estudante/pauta/exame',
                        '/troca/senha',
                        '/estudante/perfil',

                    ]}
                >

                    <MainLayout showBreadcrumb={true}>
                        <Switch location={location} key={location.pathname}>
                            <Route path="/dashboard/home" component={HomePage} />
                            <Route path="/pauta/frequencia" component={EstudanteFreque} />,
                            <Route path="/estudante/pauta/exame" component={EstudanteExame} />,
                            <Route path="/troca/senha" component={TrocaSenha} />
                            <Route path="/estudante/perfil" component={PerfilEstudante} />


                        </Switch>
                    </MainLayout>
                </Route>

            );
        }

        //ROUTAS para Departamento

        if (currentUser.authorities == "DPT") {
            return (
                <Route
                    path={[
                        '/dashboard/home',
                        '/teacher/list',
                        '/pauta/pauta',
                        '/troca/senha',
                        '/subject/list',
                        '/course/list',
                    ]}
                >

                    <MainLayout showBreadcrumb={true}>
                        <Switch location={location} key={location.pathname}>
                            <Route path="/dashboard/home" component={HomePage} />
                            <Route path="/teacher/list" component={ListTeacher} />
                            <Route path="/pauta/pauta" component={PautaRegistoAcademico} />
                            <Route path="/troca/senha" component={TrocaSenha} />
                            <Route path="/subject/list" component={ListSubject} />
                            <Route path="/course/list" component={ListCourse} />


                        </Switch>
                    </MainLayout>
                </Route>
            );
        }

        //ROUTAS para Recrutamento

        if (currentUser.authorities == "Recrutamento") {
            return (
                <Route
                    path={[
                        '/dashboard/home',
                        '/student/student',
                        '/student/list',
                        '/inscrito/list',
                        '/student/detalhes',
                        '/student/inscricao_edit',
                        '/troca/senha',

                    ]}
                >

                    <MainLayout showBreadcrumb={true}>
                        <Switch location={location} key={location.pathname}>
                            <Route path="/dashboard/home" component={HomePage} />
                            <Route path="/student/student" component={NewStudent} />
                            <Route path="/student/detalhes" component={StudentDetails} />
                            <Route path="/student/list" component={ListStudent} />
                            <Route path="/inscrito/list" component={ListInscrito} />
                            <Route path="/student/inscricao_edit" component={InscritoEdit} />
                            <Route path="/troca/senha" component={TrocaSenha} />

                        </Switch>
                    </MainLayout>
                </Route>
            );
        }


    } else {
        return (
            <Route
                path={[

                    '/login/FirebaseLogin',
                ]}
            >

                <MainLayout showBreadcrumb={true}>
                    <Switch location={location} key={location.pathname}>
                        <Route path="/login/FirebaseLogin" component={Login} />
                        <Route path="*" component={NotFound} /> {/* Adicione esta rota para página não encontrada */}
                    </Switch>
                </MainLayout>
            </Route>
        );
    }

};

export default MainRoutes;
