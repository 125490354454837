import React from 'react';
import {FormattedMessage} from 'react-intl';

import {
    IconAtom,
    IconBasket,
    IconBellRinging,
    IconBorderAll,
    IconBorderRadius,
    IconBoxMultiple,
    IconBrandChrome,
    IconBrandGravatar,
    IconBrush,
    IconBug,
    IconCalendar,
    IconChartArcs,
    IconChartCandle,
    IconChartInfographic,
    IconCircle,
    IconCircleOff,
    IconClipboardList,
    IconDashboard,
    IconDeviceAnalytics,
    IconFiles,
    IconForms,
    IconHelp,
    IconId,
    IconKey,
    IconLayoutList,
    IconLoader,
    IconLockAccess,
    IconMail,
    IconMenu,
    IconMessages,
    IconNfc,
    IconPalette,
    IconPencil,
    IconPhoneCall,
    IconPictureInPicture,
    IconReceipt2,
    IconRun,
    IconShadow,
    IconShape,
    IconShieldLock,
    IconSitemap,
    IconTools,
    IconTypography,
    IconUser,
    IconUserCheck
} from '@tabler/icons';
import authService from '../utils/services/auth';

const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics,

    IconChartArcs: IconChartArcs,
    IconClipboardList: IconClipboardList,
    IconChartInfographic: IconChartInfographic,

    IconForms: IconForms,
    IconReceipt2: IconReceipt2,
    IconPencil: IconPencil,
    IconPalette: IconPalette,
    IconShadow: IconShadow,
    IconPhoneCall: IconPhoneCall,
    IconBrandChrome: IconBrandChrome,
    IconFiles: IconFiles,
    IconAtom: IconAtom,
    IconTools: IconTools,
    IconBrush: IconBrush,
    IconLockAccess: IconLockAccess,
    IconShieldLock: IconShieldLock,
    IconKey: IconKey,
    IconTypography: IconTypography,
    IconMenu: IconMenu,
    IconBoxMultiple: IconBoxMultiple,
    IconCircleOff: IconCircleOff,
    IconCircle: IconCircle,
    IconBorderRadius: IconBorderRadius,
    IconBrandGravatar: IconBrandGravatar,
    IconShape: IconShape,
    IconUserCheck: IconUserCheck,
    IconId: IconId,
    IconLayoutList: IconLayoutList,
    IconBug: IconBug,
    IconLoader: IconLoader,
    IconRun: IconRun,
    IconUser: IconUser,
    IconHelp: IconHelp,
    IconSitemap: IconSitemap,
    IconPictureInPicture: IconPictureInPicture,
    IconMail: IconMail,
    IconMessages: IconMessages,
    IconNfc: IconNfc,
    IconCalendar: IconCalendar,
    IconBellRinging: IconBellRinging,
    IconBorderAll: IconBorderAll,
    IconChartCandle: IconChartCandle,
    IconBasket: IconBasket
};

const menuItems = {
    items: [
        
        {
            id: 'planificacao',
            title: <FormattedMessage id="planificacao" />,
            type: 'group',
            children: [
                {
                    id: 'departamento_lista',
                    title: <FormattedMessage id="lista-dpt" />,
                    type: 'item',
                    url: '/departamento/list',
                    icon: icons['IconFilePlus']
                    //target: true
                },
                {
                    id: 'docente',
                    title: <FormattedMessage id="docente" />,
                    type: 'collapse',
                    //url: '/dashboard/default',
                    icon: icons['IconUsers'],
                    breadcrumbs: false,
                    children: [
                                {
                                    id: 'cadastro',
                                    title: <FormattedMessage id="cadastro" />,
                                    type: 'item',
                                    url: '/teacher/teacher',
                                    icon: icons['IconFilePlus']
                                    //target: true
                                },
                                {
                                    id: 'lista',
                                    title: <FormattedMessage id="lista" />,
                                    type: 'item',
                                    url: '/teacher/list',
                                    icon: icons['IconListSearch']
                                    //target: true
                                }

                    ]
                },
                {
                    id: 'disciplina',
                    title: <FormattedMessage id="disciplina" />,
                    type: 'collapse',
                    url: '/dashboard/default',
                    icon: icons['IconBook'],
                    breadcrumbs: false,
                    children: [
                               {
                                    id: 'lista',
                                    title: <FormattedMessage id="lista" />,
                                    type: 'item',
                                    url: '/subject/list',
                                    icon: icons['IconListSearch']
                                   // target: true
                                }
                                


                    ]
                },
                {
                    id: 'calendario',
                    title: <FormattedMessage id="calendario" />,
                    type: 'collapse',
                    //url: '/dashboard/default',
                    icon: icons['IconBookmarks'],
                    breadcrumbs: false,
                    children: [
                                {
                                    id: 'lista',
                                    title: <FormattedMessage id="lista" />,
                                    type: 'item',
                                    url: '/calendario/list',
                                    icon: icons['IconListSearch']
                                    //target: true
                                }

                    ]
                },
                {
                    id: 'curso',
                    title: <FormattedMessage id="curso" />,
                    type: 'collapse',
                    //url: '/dashboard/default',
                    icon: icons['IconBookmarks'],
                    breadcrumbs: false,
                    children: [
                                {
                                    id: 'lista',
                                    title: <FormattedMessage id="lista" />,
                                    type: 'item',
                                    url: '/course/list',
                                    icon: icons['IconListSearch']
                                    //target: true
                                }

                    ]
                },
                {
                    id: 'curriculo',
                    title: <FormattedMessage id="curriculo" />,
                    type: 'collapse',
                    //url: '/dashboard/default',
                    icon: icons['IconBookmarks'],
                    breadcrumbs: false,
                    children: [
                                {
                                    id: 'lista',
                                    title: <FormattedMessage id="lista" />,
                                    type: 'item',
                                    url: '/curriculo/list',
                                    icon: icons['IconListSearch']
                                    //target: true
                                },
                                {
                                    id: 'cadastro',
                                    title: <FormattedMessage id="cadastro" />,
                                    type: 'item',
                                    url: '/curriculo/curriculo',
                                    icon: icons['IconListSearch']
                                    //target: true
                                }

                    ]
                },
                {
                    id: 'turma',
                    title: <FormattedMessage id="turma" />,
                    type: 'collapse',
                    children: [
                        {
                            id: 'nova_turma',
                            title: <FormattedMessage id="nova_turma" />,
                            type: 'item',
                            url: '/class/class',
                            icon: icons['IconReportAnalytics'],
                            breadcrumbs: false,
                            
                        },
                        {
                            id: 'turma_professor',
                            title: <FormattedMessage id="turma_professor" />,
                            type: 'item',
                            url: '/turma/professores',
                            icon: icons['IconReportAnalytics'],
                            breadcrumbs: false,
                            
                        },
                        {
                            id: 'lista_turma',
                            title: <FormattedMessage id="lista" />,
                            type: 'item',
                            url: '/class/list',
                            icon: icons['IconNotes'],
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'usuario',
                    title: <FormattedMessage id="usuario" />,
                    type: 'item',
                    url: '/admin/usuarios',
                    icon: icons['IconMoodSmile'],
                    breadcrumbs: false,
                   
                }
            ]
        },     
        
    ]
};

export default menuItems;
