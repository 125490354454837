import axios from 'axios'

const http=axios.create({
   
    baseURL:'http://41.94.163.4:8081',
    headers: {
        'Content-Type': 'application/json',
        // Adicione aqui o código para obter o token JWT, por exemplo, de localStorage
        'Authorization': `Bearer ${localStorage.getItem('user')}`,
      },
})

export default http

/*let refresh = false;

axios.interceptors.response.use(resp => resp, async error => {
    if (error.response.status === 403 && !refresh) {
        refresh = true;

        const response = await axios.post('refresh', {}, {withCredentials: true});

        if (response.status === 200) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data['token']}`;

            return axios(error.config);
        }
    }
    refresh = false;
    return error;
});*/