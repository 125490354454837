import React,{useEffect,useState} from 'react';
import {Typography} from '@material-ui/core';
import NavGroup from './NavGroup';
import menuItem_superuser from './../../../../menu-items/main-menu-items-super-usuario';
import menuItem_chefeRa from './../../../../menu-items/main-menu-items-registo-academico';
import menuItem_chefeRaOther from './../../../../menu-items/main-menu-items-registo-academico -other-user';
import menuItem_departamento from './../../../../menu-items/main-menu-items-departamento';
import menuItem_recrutamento from './../../../../menu-items/main-menu-items-recrutamento';
import menuItem_estudante from './../../../../menu-items/main-menu-items-estudante';
import menuItem_docente from './../../../../menu-items/main-menu-items-docente';
import menuItem_planificacao from './../../../../menu-items/main-menu-items-planificacao';
import menuItem_planificacao_tecnico from './../../../../menu-items/main-menu-items-planificacao-tecnico';

import authService from '../../../../utils/services/auth';

const MenuList = () => {
    const [carregando, setCarregando] = useState(true);
    const currentUser = authService.getCurrentUser();
    useEffect(() => {
         
        setTimeout(() => {
          setCarregando(false);
        }, 2000);
      }, [])

    //Menu do Administrador
    if(currentUser.authorities.includes("ADMIN")){
        const navItems = menuItem_superuser.items.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
        return navItems;
    }

    //Menu do chefe do RA
    if(currentUser.authorities.includes("RA_N1")){
        const navItems = menuItem_chefeRa.items.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
        return navItems;
    }
    if(currentUser.authorities.includes("RA_N2")||currentUser.authorities.includes("RA_N3")){
        const navItems = menuItem_chefeRaOther.items.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
        return navItems;
    }

     //Menu do Departamento
     if(currentUser.authorities== "DPT"){
        const navItems = menuItem_departamento.items.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
        return navItems;
    }

//Menu do Planificação
if(currentUser.authorities== "PLAN_N1"){
    const navItems = menuItem_planificacao.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });
    return navItems;
}
if(currentUser.authorities== "PLANIFICACAO"){
    const navItems = menuItem_planificacao_tecnico.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });
    return navItems;
}

    //Menu do Recrutamento
    if(currentUser.authorities== "Recrutamento"){
        const navItems = menuItem_recrutamento.items.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
        return navItems;
    }

     //Menu do Estudante
     if(currentUser.authorities== "ESTUDANTE"){
        const navItems = menuItem_estudante.items.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
        return navItems;
    }

     //Menu do Docente
     if(currentUser.authorities== "PROFESSOR"){
        const navItems = menuItem_docente.items.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });
        return navItems;
    }
    
};

export default MenuList;
