import React from 'react';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AuthService from "../../../../utils/services/auth";
import {
    Avatar,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Fade,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Paper,
    Popper,
    Typography,
    useTheme
} from '@material-ui/core';

import { IconLogout, IconSettings } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import { NavLink } from "react-router-dom";


//import UpgradePlanCard from '../../../../ui-component/cards/UpgradePlanCard';

import User1 from './../../../../assets/images/users/user-round.svg';
import { requesicoes } from '../../../../utils/services';

const useStyles = makeStyles((theme) => ({
    navContainer: {
        width: '100%',
        maxWidth: '350px',
        minWidth: '300px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%'
        }
    },
    headerAvtar: {
        cursor: 'pointer',
        ...theme.typography.mediumAvatar,
        margin: '8px 0 8px 8px !important'
    },
    profileChip: {
        height: '48px',
        alignItems: 'center',
        borderRadius: '27px',
        transition: 'all .2s ease-in-out',
        borderColor: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.light,
        '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: theme.palette.primary.main + '!important',
            color: theme.palette.primary.light,
            '& svg': {
                stroke: theme.palette.primary.light
            }
        }
    },
    profileLabel: {
        lineHeight: 0,
        padding: '12px'
    },
    listItem: {
        marginTop: '5px'
    },
    cardContent: {
        padding: '16px !important'
    },
    card: {
        backgroundColor: theme.palette.primary.light,
        marginBottom: '16px',
        marginTop: '16px'
    },
    searchControl: {
        width: '100%',
        paddingRight: '8px',
        paddingLeft: '16px',
        marginBottom: '16px',
        marginTop: '16px'
    },
    startAdornment: {
        fontSize: '1rem',
        color: theme.palette.grey[500]
    },
    flex: {
        display: 'flex'
    },
    name: {
        marginLeft: '2px',
        fontWeight: 400
    },
    ScrollHeight: {
        height: '100%',
        maxHeight: 'calc(100vh - 250px)',
        overflowX: 'hidden'
    },
    badgeyellow: {
        backgroundColor: theme.palette.warning.dark,
        color: '#fff'
    }
}));

let usuario = {}
let currentUser = null

const ProfileSection = () => {
    const classes = useStyles();
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const [selectedIndex] = React.useState(1);
    const history = useHistory();
    const [updateDom, setUpdateDom] = React.useState(false);
    const [, setCarregando] = React.useState(true);



    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const handleLogout = async () => {
        try {
            localStorage.removeItem('user');
            localStorage.clear()
            history.push(`/login`);
        } catch (err) {
            console.error(err);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function obterSaudacao() {
        let dataAtual = new Date();
        let hora = dataAtual.getHours();

        if (hora >= 0 && hora < 12) {
            return "Bom dia";
        } else if (hora >= 12 && hora < 18) {
            return "Boa tarde";
        } else {
            return "Boa noite";
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        currentUser = AuthService.getCurrentUser();

        init();
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        setTimeout(() => {
            setCarregando(false);
        }, 2000);
        prevOpen.current = open;

    }, [open]);

    async function init() {
        setCarregando(true);
        usuario = await requesicoes().getUsuarioLogado(currentUser.sub, '?campos=id,nome,apelido');
        setCarregando(false);
        setUpdateDom(!updateDom)
    }
  
    return (
        <React.Fragment>
            <Chip
                classes={{ label: classes.profileLabel }}
                className={classes.profileChip}
                icon={
                    <Avatar
                        src={User1}
                        className={classes.headerAvtar}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps, placement }) => (
                    <Fade {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <Card elevation={16}>
                                    <CardContent className={classes.cardContent}>
                                        <Grid container direction="column" spacing={0}>
                                            <Grid item className={classes.flex}>
                                                <Typography variant="h4">{obterSaudacao()}, </Typography>
                                                <Typography component="span" variant="h4" className={classes.name}>
                                                    {usuario.data?.nome + ' ' + usuario.data?.apelido}
                                                </Typography>
                                                <Typography variant="h4"> </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h5">{currentUser.authorities[0] === 'PLANIFICACAO' ? "Técnico de Planificação" : currentUser.authorities[0] === 'PLAN_N1' ? "Chefe da Planificação" :
                                                    currentUser.authorities.includes('ADMIN') ? 'Administrador do Sistema' : currentUser.authorities[0] === 'Recrutamento' ? ' Técnico Recrutamento' :
                                                        currentUser.authorities.includes('RA_N1') && !currentUser.authorities.includes('ADMIN') ? 'Chefe de Registo Académico' : currentUser.authorities[0] === 'RA_N2' ? 'Técnico Registo Académico ' : currentUser.authorities[0] === 'RA_N3' ? 'Técnico Registo Académico ' :
                                                            currentUser.authorities[0] === 'DPT' ? "Chefe Deparatmento" : currentUser.authorities[0] === 'PROFESSOR' ? 'Professor' : `Estudante ${currentUser.sub}`
                                                }</Typography>
                                            </Grid>
                                        </Grid>
                                        {/* <OutlinedInput
                                            className={classes.searchControl}
                                            id="input-search-profile"
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                            placeholder="Search profile options"
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconSearch stroke={1.5} size="1.3rem" className={classes.startAdornment} />
                                                </InputAdornment>
                                            }
                                            aria-describedby="search-helper-text"
                                            inputProps={{
                                                'aria-label': 'weight'
                                            }}
                                            labelWidth={0}
                                        /> */}
                                        <Divider />
                                        <PerfectScrollbar className={classes.ScrollHeight}>
                                            {/* <UpgradePlanCard /> */}

                                            <Divider />
                                            <List component="nav" className={classes.navContainer}>
                                                {currentUser.authorities[0] === 'ESTUDANTE' ? 
                                                 <ListItem component={NavLink}  to="/estudante/perfil"
                                                 className={classes.listItem}
                                                 sx={{ borderRadius: customization.borderRadius + 'px' }}
                                                 button
                                                 selected={selectedIndex === 3}

                                             >
                                                 <ListItemIcon>
                                                     <IconSettings stroke={1.5} size="1.3rem" />
                                                 </ListItemIcon>
                                                     
                                                         <ListItemText primary={<Typography variant="body2">Meu Perfil</Typography>} />

                                             </ListItem> : ''}
                                             {currentUser.authorities[0] === 'PROFESSOR' ? 
                                                 <ListItem component={NavLink}  to="/docente/perfil"
                                                 className={classes.listItem}
                                                 sx={{ borderRadius: customization.borderRadius + 'px' }}
                                                 button
                                                 selected={selectedIndex === 3}

                                             >
                                                 <ListItemIcon>
                                                     <IconSettings stroke={1.5} size="1.3rem" />
                                                 </ListItemIcon>
                                                     
                                                         <ListItemText primary={<Typography variant="body2">Meu Perfil</Typography>} />

                                             </ListItem> : ''}
                                             
                                                <ListItem component={NavLink} to="/troca/senha"
                                                    className={classes.listItem}
                                                    sx={{ borderRadius: customization.borderRadius + 'px' }}
                                                    button
                                                    selected={selectedIndex === 3}

                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Alterar Senha</Typography>} />
                                                </ListItem>

                                                <ListItem
                                                    className={classes.listItem}
                                                    sx={{ borderRadius: customization.borderRadius + 'px' }}
                                                    button
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={<Typography
                                                            variant="body2"
                                                        >Sair</Typography>} />
                                                </ListItem>
                                            </List>
                                           
                                        </PerfectScrollbar>
                                    </CardContent>
                                </Card>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </Popper>

        </React.Fragment>
    );
};

export default ProfileSection;
