import React from 'react';
import ReactDOM from 'react-dom';
//import {BrowserRouter} from 'react-router-dom';

import {createStore} from 'redux';
import {Provider} from 'react-redux';

import App from './App';
import reducer from './store/reducer';
import config from './config';
import './assets/scss/style.scss';
import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom/cjs/react-router-dom';
//  import '../src/utils/axios'


const store = createStore(reducer);

ReactDOM.render(
    <Provider store={store}>
        <HashRouter basename={config.basename}>
            <App />
        </HashRouter>
        {/* <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter> */}
    </Provider>,
    document.getElementById('root')
);

 serviceWorker.unregister();
